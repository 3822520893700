import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';
import { BILLINGS_QUERY } from '@/modules/billing';

export const useBillings = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId && !!variables.value.from,
  }));
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('reconciliationModal - useBillings', err);
    error();
  });

  return {
    billings,
    loading,
  };
};
