import { reject, isNil, subtract, sum, flatten } from 'ramda';
import { currency } from '@/locale/numberConfig';
import i18n from '@/imports/startup/client/i18n';

import { BILLING_TYPE, RECONCILIATION_STATUSES } from '../';

export const checkDocRefs = (document) => {
  const itemsReferences = document?.items?.filter(({ reference }) => reference);
  return Boolean(document.references?.length || itemsReferences?.length);
};

export const convertToTimeFromDateOnly = (dateTime) => (dateTime ? new Date(dateTime.split('T')[0]).getTime() : null);

export const getBillingImbalanceAmount = (imbalances) =>
  imbalances.reduce(
    (amount, { billedAmounts, orderedAmounts }) =>
      amount +
      subtract(sum(billedAmounts.map(({ amount }) => amount)), sum(orderedAmounts.map(({ amount }) => amount))),
    0
  );

export const getBillingStatus = (billing, reconciliationStatus) => {
  if (!billing.id || billing.type === BILLING_TYPE.UNBILLED_ORDER || billing.type === BILLING_TYPE.UNCERTAIN_BILLING)
    return RECONCILIATION_STATUSES.PENDING;
  return reconciliationStatus?.details
    .find(({ key }) => key === 'billings')
    .details.find(({ key }) => key === billing.id)?.status;
};

export const transformDifferences = (orderLinks, billing) => {
  const orderDifferences = orderLinks.reduce(
    (diffAmount, { order }) => {
      const orderDiff = (order.differences ?? []).reduce(
        (diffs, { pricingAmount, quantityAmount }) => ({
          pricingAmount: diffs.pricingAmount + pricingAmount,
          quantityAmount: diffs.quantityAmount + quantityAmount,
        }),
        { pricingAmount: 0, quantityAmount: 0 }
      );
      return {
        pricingAmount: diffAmount.pricingAmount + orderDiff.pricingAmount,
        quantityAmount: diffAmount.quantityAmount + orderDiff.quantityAmount,
      };
    },
    { pricingAmount: 0, quantityAmount: 0 }
  );
  const billingImbalanceAmount = getBillingImbalanceAmount(billing.imbalances);
  const other =
    billingImbalanceAmount -
    (orderDifferences.pricingAmount + orderDifferences.quantityAmount) * 100 +
    getMatchedAmount(billing);

  return billing.billingType === BILLING_TYPE.UNBILLED_ORDER
    ? reject(isNil)({
        pricing: orderDifferences.pricingAmount === 0 ? null : orderDifferences.pricingAmount * 100,
      })
    : reject(isNil)({
        pricing: orderDifferences.pricingAmount === 0 ? null : orderDifferences.pricingAmount * 100,
        quantity: orderDifferences.quantityAmount === 0 ? null : orderDifferences.quantityAmount * 100,
        other: other < Math.abs(0.01) ? null : other,
      });
};

export const createCreditRow = (billing) => {
  const amountMatched = billing.orderLinks.reduce((total, link) => total + (link.amount || 0), 0);
  return {
    ...billing,
    document: billing.source?.document,
    isFullyMatched: amountMatched === billing.netAmount,
    matchedCount: billing.orderLinks.filter(({ order }) => order).length,
    balance: billing.netAmount - amountMatched,
  };
};

export const getMatchedAmount = (billing) => {
  const relatedCredits = billing.related.map(({ orderLinks }) =>
    orderLinks.filter((orderLink) =>
      billing.orderLinks.some((link) => orderLink.order && link.order && orderLink.order.id === link.order.id)
    )
  );
  return Math.abs(reject(isNil)(flatten(relatedCredits)).reduce((total, { amount }) => total + amount, 0));
};

export const getOtherReconciliationMatchedAmount = (billing, orderIds) => {
  return billing.orderLinks
    .filter(({ order }) => order && !orderIds.includes(order.id))
    .reduce((total, { order }) => total + order.netAmount, 0);
};

export const getHeight = (mainClass) => {
  const height = document.querySelector(`#${mainClass} .card .header`)?.clientHeight;
  return height ? height + 'px' : 'auto';
};

export const formatMoneyAbs = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) && !isNil(value)
    ? Math.abs(Number(value / 100)).toLocaleString(i18n.locale, currency)
    : '-';
};

export const formatMoney = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) && !isNil(value)
    ? (value / 100).toLocaleString(i18n.locale, currency)
    : '-';
};
