import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useReconciliationDocuments(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(RECONCILIATION_DOCUMENTS, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId && !!variables.value.fromDate,
  }));
  const documentConnection = useResult(result, { nodes: [], totalCount: 0 });
  const reconciliationDocuments = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('Reconciliation modal - useReconciliationDocuments', err);
    error();
  });

  return {
    reconciliationDocuments,
    refetch,
    loading,
  };
}

const RECONCILIATION_DOCUMENTS = gql`
  query getReconciliationDocuments($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      types: [reconciliationStatement, agedDebtorsReport]
      recorded: true
    ) {
      nodes {
        id
        supplierId
        issueDate
        type
      }
    }
  }
`;
