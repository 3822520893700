<template>
  <div>
    <div class="d-flex gap-2 p-4 border-bottom bg-light-gray" :class="{ description: !reconciliationDocument }">
      <template v-if="reconciliationDocumentStatus === RECONCILIATION_STATUSES.APPROVED">
        <CheckCircleIcon width="16" height="16" class="icon-margin" />
        {{ $t('reconciliationModal.balanceAlignmentTable.approved') }}
      </template>
      <template v-else>
        <NoticeIcon width="16" height="16" class="icon-margin" />
        <p>
          <template v-if="!balancePayment">
            {{ $t('reconciliationModal.balanceAlignmentTable.missingPayablesBalance') }};
          </template>
          <template v-if="!balanceAlignment.validated">
            {{ $t('reconciliationModal.balanceAlignmentTable.balanceAlignmentRequired') }}
          </template>
        </p>
      </template>
    </div>
    <Table
      v-if="reconciliationDocument"
      v-loading="loading"
      :columns="columns"
      :data="[reconciliationDocument]"
      :hover="false"
      rounded
    >
      <template #cell-date="{ rowData: { id, issueDate } }">
        <Button type="link" class="p-0" @click.stop="$emit('open-document', id)">
          <p>{{ formatDate(issueDate) }}</p>
        </Button>
      </template>
      <template #cell-documentType="{ rowData: { type } }">
        <p>{{ $t(`document.exports.schema.type.shortName.${type}`) }}</p>
      </template>
      <template #cell-details="{ rowData: { type, issueDate } }">
        <p>
          <span v-if="documentCloseReconciliation(issueDate)"
            >{{ $t(`document.exports.schema.type.shortName.${type}`) }}
            {{
              $t('reconciliationModal.balanceAlignmentTable.forReconciliation', {
                date: formatDateShort(minusMonth(issueDate)),
              })
            }}
          </span>
          <span v-else-if="type === 'agedDebtorsReport'">
            {{ $t('reconciliationModal.balanceAlignmentTable.agedDebtorsReportForPreviousReconciliation') }}
          </span>
          <span v-else-if="type === 'reconciliationStatement'">
            {{ $t('reconciliationModal.balanceAlignmentTable.reconciliationStatementForPreviousReconciliation') }}
          </span>
        </p>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';
import { NoticeIcon, CheckCircleIcon } from '@/assets/icons';

import { DOCUMENT_STATUSES, RECONCILIATION_STATUSES } from '../../';

const TABLE_HEADER = {
  DATE: 'date',
  DOCUMENT_TYPE: 'documentType',
  DETAILS: 'details',
};

export default {
  name: 'ReconciliationDocuments',
  components: {
    Table,
    Button,
    NoticeIcon,
    CheckCircleIcon,
  },
  props: {
    balanceAlignment: { type: Object, default: () => null },
    reconciliationDocumentStatus: { type: String, default: () => null },
    balancePayment: { type: Object, default: () => null },
    dateRange: { type: Object, required: true },
    documents: { type: Array, default: () => [] },
    loading: { type: Boolean, default: () => false },
  },
  setup(props) {
    return {
      reconciliationDocument: computed(
        () => [...props.documents].sort((a, b) => new Date(b.issueDate) - new Date(a.issueDate))[0]
      ),
      DOCUMENT_STATUSES,
      RECONCILIATION_STATUSES,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t(`commons.${TABLE_HEADER.DATE}`),
          key: TABLE_HEADER.DATE,
        },
        {
          header: this.$t(`commons.${TABLE_HEADER.DOCUMENT_TYPE}`),
          key: TABLE_HEADER.DOCUMENT_TYPE,
        },
        {
          header: this.$t(`commons.${TABLE_HEADER.DETAILS}`),
          key: TABLE_HEADER.DETAILS,
        },
      ];
    },
    firstDayOfReconciliation() {
      const date = DateTime.fromJSDate(this.dateRange.toDate).startOf('month');
      return this.formatDateTwoDigitYear(date);
    },
    lastDayOfReconciliation() {
      const date = DateTime.fromJSDate(this.dateRange.toDate).endOf('month');
      return this.formatDateTwoDigitYear(date);
    },
  },
  methods: {
    documentCloseReconciliation(issueDate) {
      return (
        DateTime.fromISO(issueDate) >= DateTime.fromJSDate(this.dateRange.toDate).endOf('month').minus({ days: 1 })
      );
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : null;
    },
    formatDateTwoDigitYear(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
            timeZone: 'UTC',
          })
        : '-';
    },
    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC',
          })
        : '';
    },
    minusMonth(date) {
      return DateTime.fromISO(date).minus({ months: 1 }).toJSDate();
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  border-radius: 0px 0px 6px 6px;
  border-bottom: 0px !important;
}

.icon-margin {
  margin-top: 2px;
}

.underline {
  text-decoration: underline;
}
.bold {
  font-weight: 700;
}
</style>
