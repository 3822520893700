import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useDocuments(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(DOCUMENTS_QUERY, variables, () => ({
    enabled:
      !!variables.value.businessId &&
      !!variables.value.supplierId &&
      !!variables.value.fromDate &&
      !!variables.value.toDate,
  }));
  const documentConnection = useResult(result, { nodes: [], totalCount: 0 });
  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('ReconciliationModal useDocuments', err);
    error();
  });

  return {
    documents,
    refetch,
    loading,
  };
}

const DOCUMENTS_QUERY = gql`
  query getDocuments($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      recorded: true
    ) {
      nodes {
        id
        documentNumber
        type
      }
    }
  }
`;
