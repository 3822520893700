import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useReconciliations(args) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(RECONCILIATIONS_QUERY, args, () => ({
    enabled:
      !!args.value.businessId && !!args.value.supplierId && !!args.value.fromPeriodDate && !!args.value.toPeriodDate,
  }));

  const reconciliations = computed(() => result?.value?.reconciliations.nodes ?? []);

  onError((err) => {
    console.error('useReconciliations', err);
    error();
  });

  return {
    reconciliations,
    loading,
    refetch,
  };
}

export const RECONCILIATIONS_QUERY = gql`
  query reconciliations(
    $businessId: ID!
    $supplierId: ID
    $fromPeriodDate: ISODate
    $toPeriodDate: ISODate
    $offset: Int
    $limit: Int
    $closed: Boolean
  ) {
    reconciliations(
      businessId: $businessId
      supplierId: $supplierId
      fromPeriodDate: $fromPeriodDate
      toPeriodDate: $toPeriodDate
      offset: $offset
      limit: $limit
      closed: $closed
      enrich: false
    ) {
      nodes {
        id
        paymentDueDate
        periodStart
        periodEnd
        closed
      }
    }
  }
`;
